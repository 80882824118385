// src/pages/TermsOfService.js
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { pageVariants, pageTransition } from "../config/animations";

const TermsOfService = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Terms of Service - Harsh Banker</title>
        <meta
          name="description"
          content="Terms of Service for Harsh Banker's website."
        />
      </Helmet>
      <section className="bg-white dark:bg-gray-900 min-h-screen py-16">
        <div className="max-w-screen-xl px-4 mx-auto">
          <h1 className="mb-8 text-4xl font-extrabold text-gray-900 dark:text-white">
            Terms of Service
          </h1>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            Welcome to this website. By accessing or using our website, you
            agree to comply with and be bound by the following terms and
            conditions.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Use of the Website
          </h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            You agree to use the website only for lawful purposes and in a way
            that does not infringe the rights of others.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Intellectual Property
          </h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            All content on this website, including text, graphics, logos, and
            images, is the property of Harsh Banker and is protected by applicable
            intellectual property laws.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Limitation of Liability
          </h2>
          <p className="text-lg text-gray-700 dark:text-gray-300">
            Harsh Banker shall not be liable for any damages arising from the
            use of this website.
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default TermsOfService;
