// src/pages/RefundPolicy.js
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { pageVariants, pageTransition } from "../config/animations";

const RefundPolicy = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Refund Policy - Harsh Banker</title>
        <meta
          name="description"
          content="Refund Policy for Harsh Banker's website."
        />
      </Helmet>
      <section className="bg-white dark:bg-gray-900 min-h-screen py-16">
        <div className="max-w-screen-xl px-4 mx-auto">
          <h1 className="mb-8 text-4xl font-extrabold text-gray-900 dark:text-white">
            Refund Policy
          </h1>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            If you have mistakenly made a donation or sent money without
            intending to, you can contact me via email to request a refund.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            How to Request a Refund
          </h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            To request a refund, please send an email to{" "}
            <a
              href="mailto:itfeelsharsh@gmail.com"
              className="text-blue-600 dark:text-blue-400 underline"
            >
              itfeelsharsh@gmail.com
            </a>{" "}
            with the details of your transaction. I will review your request and
            process the refund if possible.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Conditions
          </h2>
          <p className="text-lg text-gray-700 dark:text-gray-300">
            Refunds are subject to review and approval. I reserve the right to
            refuse a refund if the request does not meet the necessary
            conditions.
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default RefundPolicy;
