// src/App.js
import React, { useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import LoadingBar from "react-top-loading-bar";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Education from "./pages/Education";
import Projects from "./pages/Projects";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import PaymentPolicy from "./pages/PaymentPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import NotFound from "./components/NotFound";


const AnimatedRoutes = () => {
  const location = useLocation();
  const loadingBarRef = useRef(null);

  useEffect(() => {
    // Start the loading bar when the location changes
    loadingBarRef.current.continuousStart();

    // Simulate loading delay (e.g., fetching data)
    // Replace this with actual data fetching logic if needed
    const timer = setTimeout(() => {
      loadingBarRef.current.complete();
    }, 500); // Adjust the delay as necessary

    // Cleanup the timer on component unmount or location change
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <LoadingBar
        color="#00FFFF"
        height={5}
        transitionTime={150}
        ref={loadingBarRef}
      />

      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route
            path="/education"
            element={
              <Layout>
                <Education />
              </Layout>
            }
          />
          <Route
            path="/projects"
            element={
              <Layout>
                <Projects />
              </Layout>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <Layout>
                <PrivacyPolicy />
              </Layout>
            }
          />
          <Route
            path="/terms-of-service"
            element={
              <Layout>
                <TermsOfService />
              </Layout>
            }
          />
          <Route
            path="/payment-policy"
            element={
              <Layout>
                <PaymentPolicy />
              </Layout>
            }
          />
          <Route
            path="/refund-policy"
            element={
              <Layout>
                <RefundPolicy />
              </Layout>
            }
          />
                  {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
          {/* Add other routes here */}
        </Routes>
      </AnimatePresence>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
};

export default App;
