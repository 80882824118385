// src/components/CookieConsent.js
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="fixed bottom-0 left-0 right-0 bg-gray-800 bg-opacity-90 text-white p-6 flex flex-col md:flex-row items-center justify-between z-50"
          initial={{ y: "100%" }}
          animate={{ y: 0 }}
          exit={{ y: "100%" }}
          transition={{ duration: 0.5 }}
        >
          <div className="mb-4 md:mb-0">
            <h1 className="text-xl font-semibold">This website likes cookies!</h1> <br></br>
            <p className="mt-2 text-sm">
              I/We may use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By clicking "Okay!", you consent to my/our use of cookies and agree to this site's Terms of Service and Privacy Policy.
            </p>
          </div> <br></br>
          <div className="flex space-x-4">
            <button
              onClick={handleAccept}
              className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
            >
              Okay! 
            </button> 

          </div><br></br>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookieConsent;
