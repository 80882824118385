import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 1.05,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.5,
};

const NotFound = () => {
  return (
    <motion.div
      className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white"
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>404 - Page Not Found</title>
        <meta
          name="description"
          content="The page you're looking for cannot be found. Navigate back to the homepage or explore other sections."
        />
        <meta name="keywords" content="404, Page Not Found, Error, Professional, Custom, Portfolio" />
      </Helmet>
      
      <div className="text-center p-10 bg-gray-800 bg-opacity-75 rounded-lg shadow-lg max-w-lg mx-auto">
        <h1 className="text-5xl font-semibold mb-4">404 - Page Not Found</h1>
        <p className="text-md mb-6">
          The page you are trying to access doesn’t exist or has been moved. Let's guide you back to a safer path.
        </p>
        <Link
        to="/"
          className="inline-flex items-center px-5 py-3 border border-gray-500 rounded-md hover:bg-gray-700 transition duration-300"
        >
          Return Home
        </Link>
      </div>

      {/* Subtle Background Animations */}
      
    </motion.div>
  );
};

export default NotFound;
