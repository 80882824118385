// src/pages/Projects.js
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { pageVariants, pageTransition } from "../config/animations";
import projectsData from "../data/projects/projects.json";
import projectBanner from "../assets/banners/project-banner.webp";

const Projects = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Projects - Harsh Banker</title>
        <meta
          name="description"
          content="Explore the projects developed by Harsh Banker, showcasing data science, machine learning, web development, and cloud infrastructure."
        />
        <meta
          name="keywords"
          content="Projects, Data Science, Machine Learning, Web Development, Cloud Infrastructure, Harsh Banker"
        />
      </Helmet>
      <section className="bg-gray-100 dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-gray-800 dark:text-white">
              Projects
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-700 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              My projects employ an extensive range of cutting-edge
              technological tools, encompassing a vast array of disciplines. I
              derive immense satisfaction from crafting Data Science projects
              and subsequently deploying them onto web applications by
              harnessing the power of cloud infrastructure.
            </p>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src={projectBanner}
              height="400"
              width="500"
              alt="Project Banner"
              loading="lazy"
            />
          </div>
        </div>
      </section>
      <section className="text-gray-800 bg-white body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="flex flex-wrap -m-4">
            {projectsData.map((project) => (
              <div key={project.id} className="p-4 lg:w-1/2 md:w-full">
                <div className="flex flex-col border-2 border-transparent rounded-lg p-8 sm:flex-row flex-col shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl bg-gradient-to-r from-blue-50 to-indigo-100">
                  <div className="flex-grow">
                    <h2 className="text-gray-900 text-xl font-bold title-font mb-3">
                      {project.name}
                    </h2>
                    <p className="leading-relaxed text-base text-gray-700 mb-4">
                      {project.description}
                    </p>
                    <a
                      href={project.link || "https://github.com/itfeelsharsh"}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3 text-indigo-600 inline-flex items-center hover:text-indigo-700 transition-colors duration-200"
                    >
                      View Project
                    </a>
                    <div className="flex mt-4">
                      {project.logos && Array.isArray(project.logos) ? (
                        project.logos.map((logo, index) => (
                          <div key={index} className="mr-4">
                            <img
                              src={logo.src}
                              alt={logo.alt}
                              width={48}
                              height={48}
                              className="rounded-full border border-gray-300 p-1 bg-white shadow-sm"
                            />
                          </div>
                        ))
                      ) : (
                        <p>‎ </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Projects;
