// src/components/Footer.js
import React from "react";
import { Link } from 'react-router-dom';
import { navFooLogo } from "../assets";

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Fetch the current year

  return (
    <footer className="bg-white border-t border-gray-200 dark:bg-gray-900 w-full">
      <div className="max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <Link to="/" className="flex items-center mb-4 sm:mb-0">
            <img
              src={navFooLogo}
              className="h-8 mr-3"
              alt="harsh-logo"
              height={100}
              width={35}
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Harsh Banker
            </span>
          </Link>
          <ul className="flex flex-wrap items-center justify-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/" className="hover:underline md:mr-6">
                Home
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/projects" className="hover:underline md:mr-6">
                Projects
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/education" className="hover:underline md:mr-6">
                Education
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/contact" className="hover:underline md:mr-6">
                Contact
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/privacy-policy" className="hover:underline md:mr-6">
                Privacy Policy
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/terms-of-service" className="hover:underline md:mr-6">
                Terms of Service
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center mb-2 sm:mb-0">
              <Link to="/payment-policy" className="hover:underline md:mr-6">
                Payment Policy
              </Link>
            </li>
            <li className="w-1/2 sm:w-auto text-center">
              <Link to="/refund-policy" className="hover:underline">
                Refund Policy
              </Link>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © {currentYear} Harsh Banker. No Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;