// src/components/Navbar.js
import React from "react";
import { Link } from 'react-router-dom';
import { navFooLogo } from "../assets";

const Navbar = () => {
  return (
    <header className="bg-yellow-500 border-gray-200 dark:bg-gray-900 w-full text-black-300 sticky top-0 z-50">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center max-w-screen-xl">
        <Link to="/" className="flex title-font font-medium items-center text-white mb-4 md:mb-0">
          <img
            src={navFooLogo}
            className="h-8 mr-3"
            alt="Harsh Banker's Logo"
            height={100}
            width={35}
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
            Harsh Banker
          </span>
        </Link>
        <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <Link to="/" className="mr-5 hover:text-white transition-colors duration-200">Home</Link>
          <Link to="/education" className="mr-5 hover:text-white transition-colors duration-200">Education</Link>
          <Link to="/projects" className="mr-5 hover:text-white transition-colors duration-200">Projects</Link>
          <Link to="/contact" className="mr-5 hover:text-white transition-colors duration-200">Contact Me</Link>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
