// src/pages/PrivacyPolicy.js
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { pageVariants, pageTransition } from "../config/animations";

const PrivacyPolicy = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Privacy Policy - Harsh Banker</title>
        <meta
          name="description"
          content="Privacy Policy for Harsh Banker's website."
        />
      </Helmet>
      <section className="bg-white dark:bg-gray-900 min-h-screen py-16">
        <div className="max-w-screen-xl px-4 mx-auto">
          <h1 className="mb-8 text-4xl font-extrabold text-gray-900 dark:text-white">
            Privacy Policy
          </h1>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            At this website, I respect your privacy. This Privacy Policy
            outlines the types of personal information I collect and how I use
            it.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Information I Collect
          </h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            I do not collect any personal data from visitors except for data
            that is publicly accessible, such as IP addresses.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            How I Use Information
          </h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            The information I collect is used to improve the functionality and
            performance of our website. I do not share your information with
            third parties.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Changes to This Policy
          </h2>
          <p className="text-lg text-gray-700 dark:text-gray-300">
            I may update our Privacy Policy from time to time. Any changes will
            be posted on this page.
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default PrivacyPolicy;
