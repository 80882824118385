// src/pages/PaymentPolicy.js
import React from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { pageVariants, pageTransition } from "../config/animations";

const PaymentPolicy = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Helmet>
        <title>Payment Policy - Harsh Banker</title>
        <meta
          name="description"
          content="Payment Policy for Harsh Banker's website."
        />
      </Helmet>
      <section className="bg-white dark:bg-gray-900 min-h-screen py-16">
        <div className="max-w-screen-xl px-4 mx-auto">
          <h1 className="mb-8 text-4xl font-extrabold text-gray-900 dark:text-white">
            Payment Policy
          </h1>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            I accept donations from all countries. All donations are appreciated
            and help in maintaining the website and developing new projects.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Accepted Payment Methods
          </h2>
          <p className="mb-4 text-lg text-gray-700 dark:text-gray-300">
            Donations can be made via various payment methods including PayPal,
            Stripe, and cryptocurrency.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Security
          </h2>
          <p className="text-lg text-gray-700 dark:text-gray-300">
            All transactions are secured and encrypted. I do not store any
            sensitive payment information.
          </p>
          <h2 className="mt-6 mb-4 text-2xl font-semibold text-gray-800 dark:text-gray-200">
            Anti-Lawsuit Disclaimer
          </h2>
          <p className="text-lg text-gray-700 dark:text-gray-300">
            By making a donation, you agree that this website is not liable
            for any issues that may arise from your donation.
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default PaymentPolicy;
