// src/pages/Home.js
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";


import {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Sec1Logo1,
  Sec1Logo2,
  Sec1Logo3,
  Sec1Logo4,
  Sec2Logo1,
  Sec2Logo2,
  Sec2Logo3,
  Sec2Logo4,
  Sec3Logo1,
  Sec3Logo2,
  Sec3Logo3,
  Sec3Logo4,
} from "../assets";

import { pageVariants, pageTransition } from "../config/animations";

const Home = () => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <Helmet>
        <title>Home - Harsh Banker</title>
        <meta
          name="description"
          content="Welcome to Harsh Banker's portfolio. I specialize in data science, machine learning, full stack web development, and cloud infrastructure."
        />
        <meta
          name="keywords"
          content="Home, Harsh Banker, Data Science, Machine Learning, Web Development, Cloud Infrastructure"
        />
      </Helmet>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
              Hi, I am Harsh Banker
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              I make software stuff which you never gonna understand, and never
              gonna use.
            </p>

            <Link
              to="/contact"
              className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              Contact me for project
            </Link>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src={Banner3}
              height="400"
              width="450"
              alt="Mockup"
              loading="lazy"
            />
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
            <h2 className="mb-8 text-3xl font-extrabold leading-tight text-center text-gray-900 dark:text-white">
              Data Science, Machine Learning, Deep Learning
            </h2>
            <div className="flex justify-center mt-4">
              <img
                src={Sec1Logo1}
                alt="HTML5 Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec1Logo2}
                alt="CSS3 Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec1Logo3}
                alt="JavaScript Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec1Logo4}
                alt="Python Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
            </div>
            <ul className="list-none pl-4 text-center">
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Developing highly scalable production-ready models for various deep learning and statistical use cases
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Experience working with Computer Vision and NLP projects
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Complex quantitative modeling for dynamic forecasting and time series analysis
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={Banner4}
              alt="Data Science Banner"
              className="max-w-full"
              width="400"
              height="500"
              loading="lazy"
            />
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-1/2">
            <img
              src={Banner1}
              alt="Web Development Banner"
              className="max-w-full"
              width="400"
              height="500"
              loading="lazy"
            />
          </div>
          <div className="w-full md:w-1/2 md:ml-8">
            <h2 className="mb-8 text-3xl font-extrabold leading-tight text-center text-gray-900 dark:text-white">
              Full Stack Web Development
            </h2>
            <div className="flex justify-center mt-4">
              <img
                src={Sec2Logo1}
                alt="HTML5 Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec2Logo2}
                alt="CSS3 Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec2Logo3}
                alt="JavaScript Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec2Logo4}
                alt="Python Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
            </div>
            <ul className="list-none pl-4 text-center">
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Building responsive website front end using React-Redux
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Developing mobile applications using Flutter, React Native, and solo Android apps using Kotlin
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Creating application backend in Node, Express & Flask
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-4 py-16 mx-auto flex flex-col-reverse md:flex-row items-center">
          <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
            <h2 className="mb-8 text-3xl font-extrabold leading-tight text-center text-gray-900 dark:text-white">
              Cloud Infra-Architecture
            </h2>
            <div className="flex justify-center mt-4">
              <img
                src={Sec3Logo1}
                alt="Python Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec3Logo2}
                alt="R Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec3Logo3}
                alt="TensorFlow Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
              <img
                src={Sec3Logo4}
                alt="PyTorch Icon"
                width={48}
                height={48}
                loading="lazy"
                className="mx-2"
              />
            </div>
            <ul className="list-none pl-4 text-center">
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Experience working on multiple cloud platforms
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases
              </li>
              <br />
              <li className="text-lg text-gray-700 dark:text-gray-300">
                ⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <img
              src={Banner2}
              alt="Cloud Infra-Architecture"
              className="max-w-full"
              width="400"
              height="500"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Home;
